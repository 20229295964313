<template lang="pug">
v-container
  template 
    div
      v-img(src="@/assets/header.jpg" aspect-ratio="2.82" v-if="annImages==''" lazy-src="@/assets/header.jpg")
      v-carousel(
        v-else
        cycle 
        height="auto"
        hide-delimiter-background
        show-arrows-on-hover
      )
        v-carousel-item(
          v-for="item in annImages",
          :key="item.id",     
        )
          v-img(:src="item.image" aspect-ratio="2.82" lazy-src="@/assets/header.jpg")
            template(v-slot:placeholder)
              v-row(
              class="fill-height ma-0"
              align="center"
              justify="center"
              )
                v-progress-circular(
                indeterminate
                color="grey lighten-5")
  v-row.mt-2
    //- Announcement
    v-col(cols="12" lg="7", xl="7")
      v-card(elevation='0')
        v-tabs(
          v-model="tab",
          grow,
          prominent,
          dark,
          background-color="primary"
        )
          v-tab Announcement
          v-tab Guidelines
        v-tabs-items(v-model="tab")
          v-tab-item
            v-card.pa-5.pt-7(text)
              div(v-if="!dataReady").text-center.mx-auto.mt-11.mb-11
                v-progress-circular(:size="70" :width="7" indeterminate color="primary")
              div(v-else-if="anns.length==0")
                p.text-center.font-weight-light(style="white-space: pre-wrap") No Announcements
              div(v-else v-for="ann in anns", :key="ann.id")
                h4.mb-n2.ml-2 {{ ann.name }}
                v-card-subtitle.ml-n1.font-weight-regular.text-justify(style="white-space: pre-wrap") {{ ann.description }}
          v-tab-item
            v-card.pt-5.pl-7.pb-5.pr-4(text)
              h3.mt-3 CS Libraries eBook Hub
                v-card-subtitle.font-weight-regular.text-justify.ml-n3 Access any of our eTextbooks, syllabi references, and frequently-used eBook collection anytime and anywhere. You can find over a thousand eBooks in the fields of natural and applied sciences by using the discovery search box in the homepage. This provides options to search by title and author’s name making it extremely easy to find the eBook you are looking for. Accessible on and off campus with no limit on its concurrent users. <strong>No download policy is strictly observed</strong>.
              h4.mt-3 How to Get Access:
                v-card-subtitle.font-weight-regular.text-justify 1. Go to the CS Libraries eBook Hub website;
                v-card-subtitle.font-weight-regular.text-justify.mt-n6 2. Click the <strong>Login With UP Mail</strong> button and enter your UP Mail credentials;
                v-card-subtitle.font-weight-regular.text-justify.mt-n6 3. Once logged in, edit your personal details in the account management form and accept the terms and conditions;
                v-card-subtitle.font-weight-regular.text-justify.mt-n6 4. Kindly wait for the CSLIB administrators to validate your account;
                v-card-subtitle.font-weight-regular.text-justify.mt-n6 5. You will receive a confirmation email regarding your validation in your UP Mail account;
                v-card-subtitle.font-weight-regular.text-justify.mt-n6 6. After validation, your next login will now be redirected to the homepage;
                v-card-subtitle.font-weight-regular.text-justify.mt-n6 7. Finally, search by author or title using the discovery search box or explore the most frequently used books, syllabi references, and general list of eBooks per institute.
              h4.mt-3 COPYRIGHT NOTICE
                v-card-subtitle.font-weight-regular.text-justify.ml-n3 The University does not authorize you to reproduce or communicate this material. The Material may contain works that are subject to copyright protection under RA 8293. Any reproduction and/or communication of the material by you may be subject to copyright infringement and the copyright owners have the right to take legal action against such infringement.
              h4.mt-3 ACCEPTABLE USE POLICY
                v-card-subtitle.font-weight-regular.text-justify.ml-n3 This statement may be agreed to by students when they access the respective Constituent University’s Learning Management System. The CU LMS is covered by the University of the Philippines’ Acceptable Use Policy, which you can access at <i><a href="https://upd.edu.ph/aup/">https://upd.edu.ph/aup/</a></i>. By logging into CU LMS, you agree to the Acceptable Use Policy of the University.

    //- Login Form and Inquiry
    v-col(cols="12" lg="5", xl="5")
      v-card.px-4.py-3(outlined, tile)
        v-row.mb-3(justify="center" align="center")
          v-col(lg="3" md="4" sm="3" cols="4")
            v-img(
              src="./index.png",
              alt="CS Library Logo",
              max-width="80px",
              aspect-ratio="1"
            ).ml-auto
          v-col(lg="9" md="8" sm="9" cols="8")
            h5.mt-2.mr-3.font-weight-light( v-if="$vuetify.breakpoint.xs"
            ) College of Science Libraries eBook Hub
            h4.mt-2.mr-3.font-weight-light( v-else
            ) College of Science Libraries eBook Hub 
        v-divider.line
        .d-flex.justify-content-center.pt-2.pb-4
          form(v-if="recaptcha")
            vue-recaptcha(sitekey="6LdU4jYcAAAAANi3XFUD2df1uc8ItRJEUx8qycBJ" @verify="googleLogin" :loadRecaptchaScript="true"  ref="recaptcha" )
          v-btn.login(
            @click="recaptcha=true",
            v-else
            :loading="this.loading",
            color="secondary" 
          ) Login with UP Mail
        v-alert(
          border="left"
          icon="mdi-alert-circle"
          color="primary"
          text
          dense
          ).text.subtitle-2.mt-2 After recaptcha, a popup window will appear for login. Please enable popup on your browser to avoid popup errors.
      v-card.inquiries.pa-3.mt-2(outlined, tile, min-height="120") 
        span.font-weight.regular
          h5.hline-one No UP Mail?
          v-card-subtitle.hline-one Please email the University Computer Center via <strong>helpdesk@upd.edu.ph</strong> with the following requirements:
            v-card-subtitle.hline-one.mt-n1 - Clear photo of UP ID or Government ID with picture
            v-card-subtitle.hline-one.mt-n6 - Selfie with above ID
            v-card-subtitle.hline-one.mt-n6 - Current Form5 for students, or Appointment Paper for Faculty
            v-card-subtitle.hline-one.mt-n6 - Screenshot of CRS Dashboard if available
          v-card-subtitle.hline-one.mt-n7 Use the appropriate subject: <strong>Request for UP Mail Account</strong>. 
      v-card.inquiries.pa-3.mt-2(outlined, tile, min-height="120") 
        span.font-weight.regular
          h5.hline-one Forgot Password?
          v-card-subtitle.hline-one Please email the University Computer Center via <strong>helpdesk@upd.edu.ph</strong> with the following requirements:
            v-card-subtitle.hline-one.mt-n1 - Clear photo of UP ID or Government ID with picture
            v-card-subtitle.hline-one.mt-n6 - Selfie with above ID
            v-card-subtitle.hline-one.mt-n6 - Current Form5 for students, or Appointment Paper for Faculty
            v-card-subtitle.hline-one.mt-n6 - Screenshot of CRS Dashboard if available
          v-card-subtitle.hline-one.mt-n7 You can also recover your UP Mail Account by visiting the link: <strong><a href="https://bit.ly/upd_upmail_reset">https://bit.ly/upd_upmail_reset</a></strong>
      v-card.inquiries.pa-2.mt-2(outlined, tile, min-height="120")
        span.font-weight.regular
          h5.hline-two Inquiries?
          v-card-subtitle.hline-two For inquiries, please email us via <strong><a href="mailto: cslib@science.upd.edu.ph">cslib@science.upd.edu.ph</a></strong>.
  v-dialog(v-model="blockDialog", width="600")
    v-card
      v-card-title.headline.grey.lighten-2 Account is on hold
      div.pa-5
        p.font-weight-light.ml-1 <strong>You cannot login right now because your account is on hold. This is due to the start of the semester or you did not have submitted a copy of your Form 5.</strong> Please submit a copy of your Form 5 at <strong>cslib@science.upd.edu.ph</strong> for reactivation. If you have other concern, please also email us at <strong> cslib@science.upd.edu.ph</strong>. Thank you!
      v-divider
      v-card-actions.px-5.pb-5
        v-spacer
        v-btn(color="primary" @click="blockDialog=false").mr-5 Got it
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";
import { db } from "../main";
import VueRecaptcha from "vue-recaptcha";
let getAnns = firebase.functions().httpsCallable("getAnns");
let incrementMonth = firebase.functions().httpsCallable("incrementMonth");
let sendRecaptcha = firebase.functions().httpsCallable("sendRecaptcha");

export default {
  components: { VueRecaptcha },
  data() {
    return {
      anns: [],
      annImages: [],
      tab: null,
      loading: false,
      password: "",
      tabs: null,
      year: new Date().getFullYear().toString(),
      month: (new Date().getMonth() + 1).toString(),
      result: null,
      recaptcha: false,
      dataReady: false,
      blockDialog: false,
    };
  },
  async created() {
    this.anns = (await getAnns()).data.announcements;
    this.$bind("annImages", db.collection("annImages").orderBy("image"));
    this.dataReady = true;
  },
  methods: {
    async googleLogin(response) {
      this.result = (await sendRecaptcha({ token: response })).data.success;
      if (this.result == true) {
        this.$refs.recaptcha.reset();
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: "select_account",
          hd: "up.edu.ph",
        });
        firebase
          .auth()
          .signInWithPopup(provider)
          .catch((err) => {
            console.log(err.code == "auth/user-disabled");
            if (err.code == "auth/user-disabled") {
              this.blockDialog = true;
            } else {
              this.$store.dispatch("setSnackbar", {
                text: `    ${err.message}`,
                icon: "mdi-block-helper",
                iconColor: "error",
              });
            }
          })
          .then(() => {
            firebase
              .auth()
              .currentUser.getIdToken(true)
              .catch((err) => {
                alert(err);
              })
              .then(() => {
                firebase
                  .auth()
                  .currentUser.getIdTokenResult()
                  .then(async (tokenResult) => {
                    if (tokenResult.claims.valid == true) {
                      if (
                        tokenResult.claims.type == "admin" &&
                        tokenResult.claims.admin
                      ) {
                        db.collection("loginCollection").add({
                          email: tokenResult.claims.email,
                          type: tokenResult.claims.type,
                          date: firebase.firestore.FieldValue.serverTimestamp(),
                        });
                      }
                      await incrementMonth({
                        collection: "logins",
                        year: this.year,
                        month: this.month,
                      });
                    }
                  });
              })
              .catch((err) => {
                alert(err);
              });
          });
      } else {
        alert("Please accomplish recaptcha");
      }
    },
  },
};
</script>
<style scoped>
.login {
  padding-left: 5%;
  padding-bottom: -5%;
}

.line {
  margin-top: -2%;
}
img.cslib {
  margin-top: -10px;
  padding-top: 5px;
  margin-left: 30px;
  margin-bottom: -10px;
}

v-card.inquiries {
  margin-top: 2%;
}

.hline-one {
  padding-left: 5%;
  padding-top: 3%;
}

.hline-two {
  padding-left: 5%;
  padding-top: 3%;
}
.carousel-control-prev-icon {
  background: black !important;
  color: black !important;
}
</style>
